<template>
    <div v-if="pageLoaded">
      <div class="main-container">
        <tz-sticky :offset-bottom="0" position="top">
          <tz-nav-bar class="no-print" :title="lang.paymentLink" left-text="" :border="false"  @click-right="closePreview">
            <template #right>
            <tz-icon name="cross" v-if="mode == 1" class="no-print"/>
          </template>
          </tz-nav-bar>
        </tz-sticky>
        <div class="form-container">
          <div v-if="hasExpired" class="text-center" style="color: red;">
            {{ $t('paymentLinkExpired') }} {{ date(paymentLink.expiresAt) }}
          </div>
          <div v-if="paymentLink.status == statuses.paid" style="text-align: center; color: #0c0; font-weight: bolder; font-size: 20px; padding: 20px 0px;">{{lang.paymentLinkPaid}} <tz-icon name="passed" /> </div>
          <div class="form-wrapper">
            <div v-if="paymentLink && paymentLink._id">
              <div class="pt-2">
                <div class="paymentLink-display-image" v-if="paymentLink.avatarUrl">
                  <tz-image :src="paymentLink.avatarUrl" @click="previewPaymentLinkImage()" />
                </div>
              </div>
              <h4 style="text-align: center; margin-top: 30px; margin-bottom: 30px;">
                {{paymentLink.description}}
              </h4>
            </div>
          </div>
        </div>
        <tz-sticky :offset-bottom="0" position="bottom" v-if="paymentLink && paymentLink._id">
          <div class="sticky-bottom">
            <div class="bottom-total no-print" style="display: block; padding-top: 20px; background: #fff">
              <div class="" v-if="!paymentLink.acceptUserSpecifiedAmount" >
                <div class="">
                  <small class="paymentLink-labels printable"> {{lang.grandTotal }}</small>
                  <div class="d-flex" style="justify-content: center">
                    <h1>
                      {{ currency(paymentLink.amount, paymentLink.currencyCode) }}
                    </h1>
                    <div style="padding-left: 10; padding-top: 7.5px; color: #990099">
                      <small>{{ paymentLink.currencyCode }}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="paymentLink.expiresAt" style="color: #888">
                {{ $t('expiresAt') }}: <span style="font-weight: 600;">{{ date(paymentLink.expiresAt) }}</span>
              </div>
              <div style="text-align: center; white-space: nowrap !important; margin-top: 20px">

                <!-- trigger payment button viewed by none logged in users -->
                <!-- <TzRequireAuth> -->
                  <tz-button type="primary" style="min-width: 150px" round @click="triggerPayNow" v-if="paymentLink.status == statuses.active" :disabled="hasExpired">
                    {{lang.payNow}}
                  </tz-button>


                <!-- Add payment from other users viewing the paymentLink -->
                <tz-popup
                  v-model:show="showPayNow"
                  round
                  position="bottom"
                  :style="{ height: 'auto' }"
                >
                  <div>
                    <tz-sticky :offset-top="50">
                      <div>
                        <tz-button type="primary" block>{{ lang.confirmAmount }}</tz-button>
                      </div>
                    </tz-sticky>
                    <div class="sub-menu">
                      <div>
                        <tz-cell-group>
                          <tz-cell
                            v-if="!paymentLink.acceptUserSpecifiedAmount"
                          >
                            <h2 style="text-align: center">
                              {{ currency(paymentLink.amount, currencyCode) }} <small style="color: #990099"> {{ currencyCode }}</small>
                            </h2>
                          </tz-cell>
                          <tz-field
                            v-model="payment.amount"
                            :autofocus="true"
                            type="number"
                            class="tz-required"
                            v-else
                            :placeholder="lang.amount"
                          />
                          <div style="padding-top: 10px" v-if="paymentLink.acceptUserSpecifiedAmount">
                            <tz-cell clickable @click="showCurrency = true" arrow-direction="right">
                              <template #right-icon>
                                <tz-icon name="arrow" class="search-icon" style="padding-top: 5px" />
                              </template>
                              <template #value>
                                <div class="d-flex">
                                  <div class="w-100" style="color:#969799">
                                    {{$t('currency')}}
                                  </div>
                                  <div class="w-100" style="text-align: right; padding-right: 15px" >
                                    {{ currencyCode }}
                                  </div>
                                </div>
                              </template>
                            </tz-cell>
                          </div>
                          <tz-field
                            v-if="!questions || !questions.length"
                            v-model="payment.note"
                            :placeholder="lang.note"
                          />
                        </tz-cell-group>
                        <tz-cell-group v-if="questions && questions.length">
                          <tz-field
                            v-for="(field, i) in questions"
                            :key="i"
                            :ref="'user-questions-'+i"
                            v-model="field.answer"
                                :class="field.required ? 'tz-required':''"
                            :placeholder="`${field.title}`"
                          />
                        </tz-cell-group>
                      </div>
                      <div style="margin: 16px; text-align: center">
                        <tz-button
                          @click="payNow()"
                          style="width: 180px; margin-top: 24px"
                          round
                          type="primary"
                          :disabled="!payment.amount"
                        >
                          {{ lang.pay }}
                        </tz-button>
                      </div>
                    </div>
                  </div>
                </tz-popup>
              </div>
            </div>
          </div>
        </tz-sticky>
        <div v-else style="padding: 50px">
            <tz-button round block type="primary" native-type="submit" @click="goTo('/')">
              <tz-icon name="wap-home-o" color="#fff"></tz-icon> {{lang.home}}
            </tz-button>
        </div>
      </div>


      <tz-action-sheet v-model:show="showCurrency" :actions="currencies" @select="selectCurrency" :title="$t('chooseCurrency')" />


    </div>
</template>
<script>
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, computed, watch } from "vue";
import { Toast, ImagePreview, Dialog, Notify } from 'tzui-vue'
import { getAppId, getLocal, formatCurrency, formatDate, getAppKey, getToken, dateBefore } from "../../utils";
import api from './api';
import TzRequireAuth from "@/components/TzRequireAuth";
import { getBusinessAccountInfo } from "@/api/common";
import {
  payFee,
  updateFee,
  getSpecific,
  getPrevPayment,
} from "@/api/common";

export default {
  name: 'DisplayInvoice',
  components: {
    TzRequireAuth
  },
  data() {
    return {
      hasExpired: false,
      businessAccount:{},
      date: formatDate,
      currencyCode: 'XAF',
      requestId: '',
      paidViaCash: true,
      showCurrency: false,
      paidViaOtherMeans: false,
      showPayNow: false,
      incomeSource: [],
      currencies: [],
      router: useRouter(),
      actions: [],
      statuses: {
        draft: 1,
        active: 2,
        paid: 3,
        cancelled: 4,
        closed: 5,
      },
      questions: [],
      status: [{
            1: 'draft', 2: 'active', 3: 'paid', 4: 'cancelled', 5: 'closed'
        },
        {
            1: '#343a40', 2: '#990099', 3: '#28a745', 4: '#dc3545', 5: '#dc3545'
        }],
      action: 0,
      pageLoaded: false,
      showActions: false,
      showAddPayment: false,
      paymentSuccessful: false,
      errors: [],
      items: [],
      lang: {},
      mode: 1, //1 for opened as child component, 2 for opened from browser link
      message: '',
      activeItem: [],
      paymentLink: {},
      appId: getAppId(),
      appInfo:{
        displayName: 'Tranzak Payment Gateway',
        appId: getAppId(),
      },
      coinsIcon: 'https://fms.tranzak.me/cdn/pf/yd7bbjkuvlaf0agy_undefined103494.63276149885151ca674bb8ef1276484de5dcaf737b7.jpg',
      payment: {
        amount: '',
        note: '',
        paymentSourceId: '',
        _id: '',
      },
      baseURL: ''
    }
  },
  props: {
    selectedInvoice:{
      type: Object,
      default() {
        title: ''
      }
    }
  },
  computed:{
    total(){
      return parseInt(this.paymentLink.totalTax) + parseInt(this.paymentLink.subtotal) || 0;
    },
    amountLeft(){
      return parseInt(this.paymentLink.subtotal) + parseInt(this.paymentLink.totalTax) - parseInt(this.paymentLink.totalPaid) || 0;
    }
  },
  watch: {
    selectedInvoice: function(data){
      if(data._id){
        this.getPaymentLink(data._id)
      }
    }
  },
  mounted () {
    // if(process.env.NODE_ENV != 'development'){
    // }
    this.setCurrencies();
    this.getCurrencies();

    const language = getLocal('app-lang') || 'en';
    this.lang = language.includes('fr')? require('@/assets/languages/fr.json'): require('@/assets/languages/en.json');
    this.currencyCode = getLocal('app-currencyCode') || 'XAF';
    console.log(this.selectedInvoice);

    // if(this.selectedInvoice && this.selectedInvoice._id){
    //   this.getPaymentLink(this.selectedInvoice._id);
    // }
    if(this.$route.params && this.$route.params.id){
      this.mode = 2;
      const id = this.$route.params.id;
      this.getPaymentLink(id);
    }else{
      // this.router.push('')
    }
    this.baseURL = `${location.origin}/acknowledgement?paymentLinkId=${this.$route.params.id}`;
  },
  methods: {
    getBusinessAccountInfo(orgId){
      getBusinessAccountInfo(orgId).then(res=>{
        if(res && res.data){
          this.businessAccount = res.data.orgAccount
        }
      })
    },
    getCurrencies(){
      api.getCurrencies().then(res=>{
        if(res.success){
          this.setCurrencies(res.data);
        }
      }).catch(err=>{
        console.log(err);
      });
    },
    setCurrencies(currencies =[]){
      if(!currencies.length){
        currencies = [
          "AUD",
          "CAD",
          "CNY",
          "EUR",
          "GBP",
          "GHS",
          "JPY",
          "KES",
          "NGN",
          "RWF",
          "SAR",
          "TZS",
          "USD",
          "VND",
          "XAF",
          "XOF",
          "ZAR",
        ];

        currencies = currencies.map(item=>{
          item = {
            id: item,
            icon: this.coinsIcon,
            name: item
          }
          return item;
        });
        if(currencies.length){
          this.currencies = currencies;
        }
      }
    },
    selectCurrency(e){
      this.showCurrency = false;
      this.currencyCode = e.name;
    },
    goTo(route){
      this.router.push(route);
    },
    paymentResponse(res) {
      if (res.data && res.data.status > 0 && res.data.transactionId) {
        Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          duration: 0
        });
        this.$router.push(`/acknowledgement?paymentLinkId=${this.$route.params.id}&transactionId=${res.data.transactionId}&requestId=${this.requestId}`);
      }else {
        Toast.fail(this.lang.operationFailed)
      }
    },
    triggerPayNow(){
      if(!this.payment.amount){
        this.payment.amount = Number(this.paymentLink.amount)? this.paymentLink.amount: null;
      }
      this.showPayNow = true;
      this.payment._id = this.paymentLink._id;
      this.payment.paymentSourceId = null;
    },
    payNow(){
      if(!Number(this.payment.amount) || this.payment.amount <= 0){
        Toast({
          duration: 3000,
          forbidClick: false,
          message: this.lang.enterAmount,
        });
      }
      const paymentParams = {
        "transactionCurrencyCode": (this.paymentLink.acceptUserSpecifiedAmount? this.currencyCode: this.paymentLink?.currencyCode ) || this.currencyCode,
        "note":this.payment.note,
        transactionAmount: this.payment.amount,
        "description": this.paymentLink.description ||  this.lang.paymentLink,
        "returnUrl": this.baseURL,
        id:  this.paymentLink.id,
      }

      if(this.questions.length){
        for(let i = 0; i<this.questions.length; i++){
          if(this.questions[i].required){
            this.questions[i].answer = String(this.questions[i].answer).trim();
            if(!this.questions[i].answer.length){
              Notify({type: 'danger', message: `"${this.questions[i].title}" ${this.lang.isRequired}`});
              this.$refs['user-questions-'+i].focus();
              return;
            }
          }
        }
        paymentParams.fields = this.questions;
      }
      api.createTransaction({...paymentParams}).then(res=>{
        if(res.success) {
          this.requestId = res.data.requestId;
           const mode = 1; // 1= web and 2 = sdk
           window.TzSdk.isTzApp((result) => {
            if(result.data.success){
              window.TzSdk.confirmPayment(
                {
                  isMapi: false, // false meaning the requestId is a fapi transaction
                  amount: res.data.amount, // Transaction amount
                  requestId: res.data.requestId, // Id of the created transaction
                  appName: this.businessAccount.name, // Mini App name or display name
                  currency: res.data.currencyCode, // Currency used in the transaction
                  isPlatformPayment:false
                },
                {
                  "x-app-key": 'MERCHANT_SOLUTION', // Merchant API key
                  "x-app-env": process.env.NODE_ENV, // Merchant API running environment.
                },
                async (result) => {
                  this.paymentResponse(result);
                },
                'PROD'
              )
            }else{
              const token = getToken()? getToken().replaceAll('"', '') : '';
              location = res.data.links.paymentAuthUrl + (token? `&token=${token}`:'');
            }
          });
          if(mode == 1){

          }else{

          }
        }
      }).catch(error=>{
         Toast({
            message: this.lang.failedToProcessYourRequest,
             duration: 8000,
            forbidClick: false,
          })
        console.log(error);
      })

    },
    closePreview(){
      this.$emit('close', true);
    },
    async getPaymentLink(id){
      this.pageLoaded = false;
      await api.getPaymentLink(id).then(res=>{
        if(res.success){
          this.paymentLink = {...this.preparePaymentLink(res.data)};
          this.hasExpired = dateBefore(this.paymentLink);
          this.getBusinessAccountInfo(this.paymentLink.orgId);
          this.currencyCode = this.paymentLink.currencyCode? this.paymentLink.currencyCode:this.currencyCode;
          if(!this.paymentLink.avatarUrl) this.paymentLink.avatarUrl = 'https://fms.tranzak.me/cdn/pf/09ghaw7495mtsjja_undefined107804.57393787343tranzak-wallet.png';
          this.pageLoaded = true;
        }else{
          this.pageLoaded = false;
          Dialog.confirm({
            message: this.lang.failedToLoadPaymentLink,
            confirmButtonText: this.lang.yes,
            cancelButtonText: this.lang.no
          }).then(res=>{
            this.getPaymentLink(this.$route.params.id);
          })
        }
      }).catch(err=>{
        this.pageLoaded = false;
        Dialog.confirm({
          message: this.lang.failedToLoadPaymentLink,
          confirmButtonText: this.lang.yes,
          cancelButtonText: this.lang.no
        }).then(res=>{
          this.getPaymentLink(this.$route.params.id);
        })
        console.log(err);
      })
    },
    toggleMeans(mode = 1){
      if(mode == 1){
        this.paidViaOtherMeans = !this.paidViaCash;
      }else{
        this.paidViaCash = !this.paidViaOtherMeans;
      }
    },
    preparePaymentLink(data){
      this.questions = data.fields? data.fields.map(e=>{return {...e, answer: ''}}): [];
      return {...data, acceptUserSpecifiedAmount: data.acceptUserSpecifiedAmount == 0 || !data.acceptUserSpecifiedAmount? false: true, items: data.jsonData || []};

    },
    forwardToEdit(paymentLink){
      this.router.push({name: 'paymentLinks-create', params: {paymentLink: JSON.stringify({...paymentLink, acceptPartialPayments: paymentLink.acceptPartialPayments == 0? false: true})}})
    },
    currency: function(value, currency){
      return formatCurrency(value, currency, getLocal('app-lang'));
    },
    previewPaymentLinkImage(){
      ImagePreview({images: [this.paymentLink.avatarUrl], closeable: true});
    },
    addPayment(){
      if(!this.payment.amount){
        Toast({
          duration: 3000,
          forbidClick: false,
          overlay: true,
          message: this.lang.validAmount,
        });
        return false;
      }
      if(!this.payment.note){
        Toast({
          duration: 3000,
          forbidClick: false,
          message: this.lang.validNote,
        });
        return false;
      }

      // Toast.loading({
      //   duration: 0,
      //   forbidClick: true,
      //   message: this.lang.loading + '...',
      // });
      this.payment.paymentSourceId = this.paidViaCash? 2: 3;
      this.payment._id = this.paymentLink._id;
      this.errors = [];

      api.addPayment({...this.payment}).then((res) => {
        Toast.clear();
        if(res.success){
          Dialog.alert({
            // title: 'Title',
            message: this.lang.paymentAdded,
          }).then(() => {
          // on close
          });
          this.showAddPayment = false;
          this.getPaymentLink(res.data.paymentLinkId);
        }else if(res.errorMsg){
           Dialog.alert({
              // title: 'Title',
              message: res.errorMsg,
            }).then(() => {
              // on close
            });
          this.errors.push(res.errorMsg);
        }else{
           Dialog.alert({
              // title: 'Title',
              message: this.lang.failedToAddPayment,
            }).then(() => {
              // on close
            });
        }
      }).catch((err) => {
         Dialog.alert({
            // title: 'Title',
            message: this.lang.failedToAddPayment,
          }).then(() => {
            // on close
          });
        console.log(err);
      })
    },
  }
};
</script>
<style lang="less">
@import "../../common/css/vars";
.form-container {
  padding-top: 10px;
  // margin-bottom: 30px;
  // background-color: var(--tz-primary-color);
  position: relative;
  border: 0px !important;
}
.headings {
  text-align: left;
  padding-left: 30px;
  padding-top: 30px;
  .name {
    color: #eee;
    font-size: 16px;
    margin-bottom: 25px;
  }

  .main-heading {
    color: white;
    font-size: 36px;
    font-weight: bold;
    line-height: 1em;
  }
}
.form-header-wrapper {
  height: 150px;
  position: relative;
}
.form-wrapper {
  background-color: white;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 0px 10px;
  // box-shadow: 0px -4px 5px 0px rgba(120, 120, 120, 0.5);
  .paymentLink-row{
    padding: 7.5px 5px
  }
  .paymentLink-display-image{
    margin: 10px 0px;
    padding: 5px;
    border-radius:5px;
    background-color:#efefef88;
    text-align: center;
    .tz-image{
      img{
        max-height: 250px !important;
        border-radius: 4px;
      }
    }
  }
}
.paymentLink-labels{
    color: #0005;
    text-decoration: underline;
    // color: var(--tz-primary-color);
    font-weight: bolder;
  }
.bottom-total{
  padding: 10px 10px;
  background-color: #fffbff;
  text-align: center;
}
.sticky-bottom{
  background-color: #bbb;
  width: 100vw;
}
.paymentLink-download{
  text-align: center;
  padding: 10px 0px;
}
.items-title, .item-detail{
  display: flex;
}
.items-title div:nth-child(1){
  width: 75%;
}
.items-title div:nth-child(2){
  white-space: nowrap;
}
.item-detail div:nth-child(2){
  padding-left: 10px;
}
.sub-menu{
  padding: 10px 7.5px;
  text-align: center;
}
.paymentLink-actions{
  margin-bottom: 7.5px;
}
.w-100{
  width: 100%;
}
.pay-switches{
  text-align: left;
  padding: 10px 15px;

  display: flex;
  align-items: center;
}
.pay-switches >div:nth-child(2){
  width:30%;
  text-align: right;
}
.pay-switches > div:first-child{
  width: 70%;
}
.paymentLink-display-items{
  padding: 5px;
  border: 1px solid #ccc6;
  border-radius: 10px;
  margin-top: 10px;
}
small{
  font-size: 11px
}
.printable table{
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px;
  td{
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
  }
  th{
    padding: 5px;
    border: 1px 0px 1px 0px solid #ccc;
  }
}
</style>
<style lang="less">
  .printable{
    display: none;
  }
  .no-print{
      display: block;
    }
  @media print{
    html,body{
      width: 100% !important;
      max-width: 100%;
      flex-grow: 1;
    }
    .printable{
      display: block !important;
    }
    .no-print{
      display: none !important;
    }
    .d-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .w-25 {
      width: 25% !important;
    }

    .w-50 {
      width: 50% !important;
    }

    .w-75 {
      width: 75% !important;
    }

    .w-100 {
      width: 100% !important;
    }
    .row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      .col-6:nth-child(odd) {
        text-align: right;
      }
    }
    .col {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .paymentLink-status{
      font-weight: bolder;
      color: #222 !important;
    }
  }
  .tz-popover__content{
    width: 160px!important;
  }
  .tz-popover__content .tz-popover__action .tz-popover__action-text{
    white-space: nowrap !important;
    padding: 5px !important;
}
.text-right{
  text-align: right !important;
}
.invoice-status{
  font-size: 22px;
  font-weight: bolder;
  color: #222 !important;
}
</style>