import request from '@/utils/axios'
import  urls  from '../../utils/urls.js';
import { getPlatformId } from '../../utils/index';

const baseUrl = urls.baseURL;
// const msURL = urls.localUrl; //@@TODO CHANGE TO REMOTE when pushing
const msURL = baseUrl + '/tz988'; // remote url


export default {
    deleteRequestInvoice: (params = {}) => request.post(msURL + '/v1/invoice/delete', params),
    createRequestInvoice: (params = {}) => request.post(msURL + '/v1/invoice/create', params),
    createInvoice: (params = {}) => request.post(baseUrl + '/fapi/invoice/create', params),
    updateInvoice: (params = {}) => request.post(baseUrl + '/fapi/invoice/update', params),
    updateRequestInvoice: (params = {}) => request.post(msURL + '/v1/invoice/update', params),
    activateInvoice: (params = {}) => request.post(baseUrl + '/fapi/invoice/submit', params),
    cancelInvoice: (params = {}) => request.post(baseUrl + '/fapi/invoice/cancel', params),
    addPayment: (params = {}) => request.post(baseUrl + '/fapi/invoice/add-payment', params),
    markAsPaid: (params = {}) => request.post(baseUrl + '/fapi/invoice/mark-as-paid', params),
    getInvoices: (params = 1) => request.get(baseUrl + '/fapi/invoice/list?pageSize=10&page=' + params, params),
    getPaymentLink: (params = 1) => request.get(msURL + '/v1/payment-link/get-payment-link/' + params, params),
    getInvoiceRequests: (params = 1) => request.post(msURL + '/v1/invoice/all/', params),
    getPayments: (params = 1) => request.get(baseUrl + '/fapi/invoice/list-payments?pageSize=10&page=' + params, params),
    getAccounts: (params = '') => request.get(baseUrl + '/fapi/mch-account/list?pageSize=50', params),
    
    getCurrencies: () => request.get(baseUrl + '/fapi/fee/currencies'),

    createTransaction: (params = {}) => request.post(msURL + '/v1/payment-link/create-payment-request', params)
}